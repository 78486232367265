import { Fragment, useEffect } from 'react'
import type { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import Prism from 'prismjs'
import classNames from 'classnames'
import SiteLayout from '@src/ui/components/site-layout'
import Image from 'next/image'

require('prismjs/components/prism-swift.min')

export function HomepageSection(props: {
  title: string
  subtitle: string
  color: string
  gradient: string
  items: any[]
}) {
  useEffect(() => {
    Prism.highlightAll()
  }, [])
  return (
    <section
      className={classNames(
        'border-gray-600/10 md:pb-36 md:pt-20 pt-12 pb-20 border-b',
        props.gradient
      )}
    >
      <div className='sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 max-w-md px-4 mx-auto'>
        <div className='relative'>
          <h2 className={`font-semibold tracking-wider text-center text-${props.color} uppercase`}>
            {props.title}
          </h2>
          <p className='sm:text-4xl text-slate-900 dark:text-slate-50 mt-2 text-3xl font-bold tracking-tight text-center'>
            {props.subtitle}
          </p>
          <div
            className={`h-[3px] w-[100px] absolute -bottom-5 left-1/2 -translate-x-1/2 bg-${props.color}`}
          />
        </div>
        <div className='md:mt-20 relative mt-16'>
          <div
            className={`w-[2px] h-[110%] md:h-[130%] left-[10px] md:left-1/2 top-1/2 bg-gradient-to-b from-${props.color}/0 to-${props.color} absolute -translate-y-1/2 -translate-x-1/2`}
          />
          <div
            className={`border-t-[10px] border-b-0 border-l-[10px] border-r-[10px] absolute left-[10px] md:left-1/2 top-[105%] md:top-[115%] -translate-x-1/2 border-t-${props.color} border-r-transparent border-l-transparent`}
          />
          <div className='md:grid md:grid-cols-2 md:gap-20 max-w-4xl mx-auto pl-[36px] md:pl-0'>
            <div className='md:space-y-8 flex flex-col space-y-4'>
              {props.items.slice(0, 2).map((item) => (
                <div
                  key={item.title}
                  className={`border-${props.color}/20 rounded-xl bg-white/50 md:bg-white/30 p-4 border`}
                >
                  <h3 className='mb-2 text-xl font-semibold'>{item.title}</h3>
                  <p className='text-gray-800'>{item.description}</p>
                </div>
              ))}
            </div>
            <div className='md:space-y-8 md:mt-0 flex flex-col mt-4 space-y-4'>
              {props.items.slice(2).map((item) => (
                <div
                  key={item.title}
                  className={`border-${props.color}/20 rounded-xl bg-white/50 md:bg-white/30 p-4 border`}
                >
                  <h3 className='mb-2 text-xl font-semibold'>{item.title}</h3>
                  <p className='text-gray-800'>{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const code = `let jobHandler = AWS.Function(
    "job-handler",
    targetName: "JobHandler",
    url: .enabled(cors: true),
    memory: 512,
    timeout: .seconds(10)
)

let queue = AWS.Queue("job-queue")

queue.subscribe(jobHandler)

let server = AWS.WebServer(
    "hummingbird-server",
    targetName: "App",
    concurrency: 1,
    autoScaling: .init(
        maximumConcurrency: 10,
        metrics: [.cpu(50), .memory(50)]
    )
)

server.link(queue)`

const Home: NextPage = () => {
  return (
    <SiteLayout>
      <NextSeo
        title='Build and Deploy Server Side Swift'
        description='Swift Cloud is the fastest way to build and deploy server side Swift applications.'
        canonical='https://swift.cloud'
      />
      <section className='overflow-hidden'>
        <div className='lg:relative lg:py-20 sm:py-16 py-8'>
          <div className='gap-y-12 gap-x-8 lg:max-w-8xl lg:grid-cols-12 xl:gap-x-16 grid items-center max-w-2xl grid-cols-1 px-4 mx-auto'>
            <div className='lg:text-left lg:col-span-6 relative z-10 text-center'>
              <h1 className='bg-gradient-to-r from-orange-100 via-orange-200 to-orange-50 bg-clip-text font-display sm:text-7xl text-5xl font-bold tracking-tight text-transparent'>
                Build &amp; Deploy
                <br />
                Server Side Swift
              </h1>
              <p className='sm:text-3xl mt-4 mb-10 text-2xl text-white'>
                Swift Cloud is the fastest way to build and deploy server side Swift applications.
              </p>
              <a
                href='https://github.com/swift-cloud/swift-cloud'
                className='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 inline-block px-6 py-3 text-lg font-medium text-white bg-gradient-to-l from-[#FE792D] via-[#FE6839] to-[#FF504D] border border-transparent rounded-full shadow-sm'
              >
                Try the SDK
              </a>
            </div>
            <div className='lg:static lg:col-span-6 xl:pl-10 relative'>
              <div className='relative rounded-2xl bg-[#0A101F]/80 ring-1 ring-white/10 backdrop-blur'>
                <div className='-top-px left-20 right-11 bg-gradient-to-r from-orange-300/0 via-orange-300/70 to-orange-300/0 absolute h-px' />
                <div className='-bottom-px left-11 right-20 bg-gradient-to-r from-orange-300/0 via-orange-300/70 to-orange-300/0 absolute h-px' />
                <div className='pt-4 pl-4'>
                  <svg aria-hidden='true' className='h-2.5 w-auto stroke-slate-500/30' fill='none'>
                    <circle cx='5' cy='5' r='4.5' />
                    <circle cx='21' cy='5' r='4.5' />
                    <circle cx='37' cy='5' r='4.5' />
                  </svg>
                  <div className='flex items-start px-1 mt-6 text-sm'>
                    <div
                      aria-hidden='true'
                      className='border-slate-300/5 text-slate-600 pr-4 font-mono border-r select-none'
                    >
                      {Array.from({
                        length: code.split('\n').length
                      }).map((_, index) => (
                        <Fragment key={index}>
                          {(index + 1).toString().padStart(2, '0')}
                          <br />
                        </Fragment>
                      ))}
                    </div>
                    <pre className='language-swift flex pb-6 overflow-x-auto'>
                      <code className='px-4'>{code}</code>
                    </pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='sm:pt-24 relative pt-16 overflow-hidden bg-white border-b border-gray-200'>
        <div className='sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl max-w-md px-4 mx-auto text-center'>
          <div>
            <h2 className='font-semibold tracking-wider text-center text-orange-400 uppercase'>
              Serverless Swift
            </h2>
            <p className='sm:text-4xl text-slate-900 dark:text-slate-50 mt-2 text-3xl font-bold tracking-tight text-center'>
              Modern tools for server side Swift.
            </p>
            <p className='max-w-prose mx-auto mt-5 text-xl text-gray-500'>
              We built Swift Cloud to bring modern features to developers looking to build backend
              applications and websites in the Swift language.
            </p>
          </div>
          <div className='sm:-mb-10 lg:-mb-20 mt-12 -mb-10'>
            <div className='ring-1 ring-black ring-opacity-5 rounded-lg shadow-xl'>
              <Image
                src='/dashboard.jpg'
                alt='Swift Cloud realtime logs'
                layout='responsive'
                width={1120}
                height={603}
              />
            </div>
          </div>
        </div>
      </div>
      <HomepageSection
        title='Build'
        subtitle='Build your backend project with Swift.'
        color='orange-400'
        gradient='bg-gradient-to-r from-white to-orange-50'
        items={[
          {
            title: '🛠 Build anything',
            description: 'Bring your own code or pick one of our templates to deploy instantly.'
          },
          {
            title: '⚡ ️HTTP/3',
            description:
              'Take advantage of HTTP/3 + QUIC for the lowest latency and highest level of security.'
          },
          {
            title: '🏎 Blazingly fast',
            description:
              'Harness the small memory footprint, quick startup time and deterministic performance of Swift.'
          },
          {
            title: '🔐 Secrets management',
            description:
              'Manage environment variables and secrets with your team securely with dictionaries.'
          }
        ]}
      />
      {/* Need this div to JIT dynamic classname styles in HomepageSection */}
      <div className='border-orange-400/20 bg-gradient-to-r from-white to-orange-50 from-orange-400/0 to-orange-400 border-t-orange-400 hidden text-orange-400 bg-orange-400' />
      <HomepageSection
        title='Preview'
        subtitle='Iterate and preview with cutting-edge tools.'
        color='[#FF604D]'
        gradient='bg-gradient-to-l from-white to-[#fdf4f3]'
        items={[
          {
            title: '🔎 Automatic builds & previews',
            description:
              'Connect to GitHub and deploy every commit. Preview deployments for every pull request.'
          },
          {
            title: '🗂 Multiple environments',
            description:
              'Set variables and secrets for each environment. Deploys for staging, production & more.'
          },
          {
            title: '💪 Bring your team️',
            description:
              'Add your whole team with invite links and scoped user permissions for powerful collaboration.'
          },
          {
            title: '🔙 Instant rollbacks',
            description:
              'Bug in production? Rollback to previous deployments instantly from our dashboard.'
          }
        ]}
      />
      {/* Need this div to JIT dynamic classname styles in HomepageSection */}
      <div className='border-[#FF604D]/20 from-[#FF604D]/0 to-[#FF604D] border-t-[#FF604D] hidden text-[#FF604D] bg-[#FF604D]' />
      <HomepageSection
        title='Deploy'
        subtitle='Launch and scale with confidence.'
        color='[#FE246F]'
        gradient='bg-gradient-to-r from-white to-[#fff4f8]'
        items={[
          {
            title: '🔥 Serverless Swift',
            description:
              'Deploy unlimitted applications without managing servers. Only pay for what you use.'
          },
          {
            title: '📈 Scale instantly',
            description:
              'With 100ms cold starts, apps scale instantly to thousands of requests per second.'
          },
          {
            title: '🌎 Global deployments',
            description:
              'Deploy to 60+ POPs around the world providing the absolute lowest latency.'
          },
          {
            title: '📊 Real-time metrics',
            description:
              'View critical metrics like requests, errors, response times and logs, all in real-time.'
          }
        ]}
      />
      {/* Need this div to JIT dynamic classname styles in HomepageSection */}
      <div className='border-[#FE246F]/20 from-[#FE246F]/0 to-[#FE246F] border-t-[#FE246F] hidden text-[#FE246F] bg-[#FE246F]' />
    </SiteLayout>
  )
}

export default Home
