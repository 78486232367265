import Link from 'next/link'

function Header() {
  return (
    <header className='shadow-slate-900/5 dark:shadow-none lg:max-w-8xl flex flex-wrap items-center justify-between px-4 py-5 mx-auto transition duration-500 shadow-md'>
      <div className='sm:flex-row flex flex-col items-center justify-between w-full'>
        <Link href='/' legacyBehavior>
          <a className='sm:mb-0 flex items-center mb-4'>
            <img className='h-8 mr-2' src='/icon.svg' alt='Swift Cloud' />
            <span className='whitespace-nowrap text-xl font-semibold text-white'>Swift Cloud</span>
          </a>
        </Link>
        <div className='shrink sm:justify-end flex items-center justify-center'>
          <ul className='sm:space-x-6 flex items-center space-x-8'>
            <li>
              <a
                className='text-lg font-medium text-white'
                target='_blank'
                rel='noreferrer'
                href='https://join.slack.com/t/swift-cloud-app/shared_invite/zt-2olp1dm7l-ufp6UVM2_t3qe85YqIYwMQ'
              >
                Slack Group
              </a>
            </li>
            <li>
              <Link href='/blog' legacyBehavior>
                <a className='text-lg font-medium text-white'>Blog</a>
              </Link>
            </li>
            <li className='sm:block hidden'>
              <a
                href='https://github.com/swift-cloud/swift-cloud'
                className='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 px-5 py-2 text-base font-medium text-white bg-gradient-to-l from-[#FE792D] via-[#FE6839] to-[#FF504D] border border-transparent rounded-full shadow-sm'
              >
                Try the SDK
              </a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
