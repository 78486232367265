import React, { ReactNode } from 'react'
import Header from '@ui/components/site-header'
import Footer from '@ui/components/site-footer'

function SiteLayout({
  children
}: {
  children: ReactNode
}) {
  return (
    <>
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default SiteLayout